
.app{
	overflow: hidden;
	display: flex;
	flex-direction: column;
	min-height: 200vh;
	background-color: #fff;
 }
 .container{
	margin: 0 auto;
 }


 .fon{
	background-image: url('./images/mainPage/fon.webp');
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
}

.fon:after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	background: #000;
	opacity: .7;
	z-index: -1;
}
button, [type='button'], [type='reset'], [type='submit']{
	background-color: #ea580c;
	color: #fff;
 }

 .ant-drawer .drawer .ant-drawer-wrapper-body
 {
	background-image: url('./images/menu/menu.webp');
	background-size: cover;
	width: 100%;
	height: 100vh;
	z-index: 0;
	position: relative;
}

.ant-drawer .drawer .ant-drawer-wrapper-body:after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	background: #000;
	opacity: .7;
	z-index: -1;
}

