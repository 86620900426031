/* @import '~antd/dist/antd.css'; */
/* @import 'antd/dist/antd.variable.min.css'; */
/* @import 'antd/dist/antd.variable.min.css'; */
@tailwind base;
@tailwind components;
@tailwind utilities;



/* img, */
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block !important;
}

/* button, [type='button'], [type='reset'], [type='submit']{
  background-color: #f97315;
  color: #fff;
} */

ol, ul, dl {
  margin-bottom: 0 !important;
}

